<script>
// import Layout from "../../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { FormWizard, TabContent,WizardButton } from "vue-form-wizard";
import Swal from "sweetalert2";
import { fetchSettings } from "../api";
// import infoStaff from './infoStaff.vue';
import { getDropDownElementsByTypeApi } from "@/api/common";
import {required,decimal,maxLength,requiredIf,minLength ,email} from "vuelidate/lib/validators";
import { emailFormatter} from "@/helpers/functions";

// import Infrastructure from './infrastructure.vue';
import Multiselect from 'vue-multiselect'

export default {
    page: {
        title: "Base de Vie",
        meta: [{ name: "description", content: appConfig.description}],
    },
    import : { fetchSettings,getDropDownElementsByTypeApi },
    components: { /*Layout, PageHeader,*/WizardButton, FormWizard,TabContent/* ,infoStaff */,Multiselect},
    props : {
      base : null ,
    },
    data() {
      return {
      state :[],
      tableData: [],
      title: "New BaseLife",
      uuid: "",
      disabled: true,
      info: {
        name:'',
        email:'',
        phone:'',
        state:'',
        address:'',
        rooms:'',
        offices:'',
        restaurants:'',
        kitchens:'',
        stores:'',
        hobbies:'',
        regions:'',
        sites:'',
        zones:'',
        blocs:'',
        latitude:'',
        longitude:'',
        area:'',
        uuid:'',
        id:''
      },
      restInfo: {
        name:'',
        email:'',
        phone:'',
        state:'',
        address:'',
        rooms:'',
        offices:'',
        restaurants:'',
        kitchens:'',
        stores:'',
        hobbies:'',
        regions:'',
        sites:'',
        zones:'',
        blocs:'',
        latitude:'',
        longitude:'',
        area:'',
        uuid:'',
        id:''
      },
      selectedEmployees:[],
       items: [
        {
          text: "Master Data",
          to: { name: 'master_data' }
        },
        {
          text: "Lifebase",
          active: true
        }
      ],
      submitted : false,
      disabledAdd : false,
      disabledEdit : true,
      disabledDelete : true,
      disabledCancel : true,
      lastSelected : null,
      resetForm:false,

    };
  },
  validations: {
      info:{
        name : { 
          required,
           maxLength: maxLength(40), 
           minLength: minLength(3), 
        },
        email : { 
          required, 
          email: (val) => email(emailFormatter(val)),
        },
        phone : { 
          maxLength: maxLength(14),
           minLength: minLength(8), 
        },
        // state : { 
        //   //  maxLength: maxLength(5),
        // },
        address : { 
          required,
           maxLength: maxLength(64),
        },
        rooms : { 
           maxLength: maxLength(5),
        },
        offices : { 
           maxLength: maxLength(6),
        },
        restaurants : { 
           maxLength: maxLength(5),
        },
        kitchens : { 
           maxLength: maxLength(5),
        },
        stores : { 
           maxLength: maxLength(5),
        },
        hobbies : { 
           maxLength: maxLength(5),
        },
        regions : { 
           maxLength: maxLength(5),
        },
        sites : { 
           maxLength: maxLength(5),
        },
        zones : { 
           maxLength: maxLength(5),
        },
        blocs : { 
           maxLength: maxLength(5),
        },
        latitude : { 
           maxLength: maxLength(20),
        },
        longitude : { 
           maxLength: maxLength(20),
        },
        area : { 
           maxLength: maxLength(20),
        },
      }
  },
  
  watch:{
      base: function (val){
        this.uuid           = null;
        this.info           = this.restInfo;
        this.disabled       = true;
        this.disabledAdd    = false;
        this.disabledDelete = true;
        this.disabledEdit   = true;
        this.disabledCancel = true;
        this.tableData    = [];
        this.$refs.wizard.navigateToTab(0)
        if(val.length > 0 && undefined != val[0] ){
          this.disabledAdd    = false;
          this.disabledDelete = false;
          this.disabledEdit   = false;
          this.info = val[0]; 
          this.uuid = val[0].uuid; 
          this.tableData = this.state.filter(x=> x.id == val[0].state);
          this.lastSelected = val;
        }
     }
  },
  created() {
    this.fetchStates();
  },

  methods:{
    addBtn(){
      this.disabled       = false;
      this.info           = this.restInfo;
      this.tableData      = [];
      this.info.uuid      = "";
      this.disabledAdd    = true;
      this.disabledEdit   = true;
      this.disabledDelete = true;
      this.info.uuid      = "";
    },
    duplicateBtn(){
      this.disabled = false;
      this.info.uuid = "";
      this.disabledAdd    = true;
      this.disabledEdit   = true;
      this.disabledDelete = true;
    },
    editBtn(){
      this.disabled = false;
      this.disabledAdd    = true;
      this.disabledEdit   = true;
      this.disabledDelete = true;
    },
    cancelBtn(){
      Swal.fire({
        title: "Êtes-vous sûr de annuler?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        confirmButtonText: "Annuler!",
      }).then((result) => {
        if (result.value) {
          this.disabled = true;
          this.disabledAdd    = false;
          this.disabledEdit   = true;
          this.disabledDelete = true;
          this.info           = this.restInfo;
          this.$refs.wizard.navigateToTab(0)
          if(this.lastSelected != null){
            this.disabledEdit   = false;
            this.disabledDelete = false;
            this.base = null;
            this.base = this.lastSelected;
          }
        }
      });
    },
    assignID(state){
      this.info.state =state.designation;
    },
    fetchStates(){
      getDropDownElementsByTypeApi('state')
     .then((res) => {
            this.state = res.data.list;
            var status = res.data.status;
            }).catch(() => {
                // error.response.status Check status code
                
             }).finally(() => {
                //Perform action in always
            });
    },
    isLastStep() {
      if (this.$refs.wizard) {

        return this.$refs.wizard.isLastStep
      }
      return false
    },
    onComplete(){
      // this.$refs.principal.nextTab();
      this.submitted = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitted = false
        if(this.info.uuid == ""){
          this.addLifeBase()
        }else{
          this.updateLifeBase()
        }
      }else{
          this.$refs.wizard.navigateToTab(0)
        Swal.fire("Erreur!", "Merci de bien vouloir vérifier ce formulaire avant de l'envoyer.", "error")
      }
    },
    clearForm(){
        this.info.name        = '';
        this.info.email       = '';
        this.info.phone       = '';
        this.info.state       = '';
        this.info.address     = '';
        this.info.rooms       = '';
        this.info.offices     = '';
        this.info.restaurants = '';
        this.info.kitchens    = '';
        this.info.stores      = '';
        this.info.hobbies     = '';
        this.info.regions     = '';
        this.info.sites       = '';
        this.info.zones       = '';
        this.info.blocs       = '';
        this.info.latitude    = '';
        this.info.longitude   = '';
        this.info.area        = '';
        this.info.uuid        = '';
        this.info.id          = '';
        this.tableData        = [];
    },
    addLifeBase(){
      let loader = this.$loading.show({
        color: '#000000',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 999,
      })
      this.$http.post('/base/lifebases/store',this.info)
      .then(response => {
        var data = response.data;
        if(data.errors){
          this.$toast.error(data.msg);
          // Swal.fire("Erreur!", response.data.msg, "error")
        }else{
          this.clearForm();
          this.$toast.success(data.msg);
          // Swal.fire("Félicitations!", response.data.msg, "success");
          this.$emit('realoadLifeBaseList', true);
          
          this.disabled = true;
        }
          this.disabledAdd = false;
          this.$refs.wizard.navigateToTab(0)
        loader.hide();
      })
      .catch(error => {
        loader.hide();
        Swal.fire("Erreur!", error, "error")
      })
    },
    updateLifeBase(){
      let loader = this.$loading.show({
        color: '#000000',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 999,
      })
      this.$http.post('/base/lifebases/update',this.info)
      .then(response => {
        var data = response.data;
        if(data.errors){
          // Swal.fire("Erreur!", response.data.msg, "error")
          this.$toast.error(data.msg);
        }else{
          if(data.status == 500){
            this.$toast.error(data.msg);
          }else{
            this.$toast.success(data.msg);
          }
          // 
            this.clearForm();
            // Swal.fire("Félicitations!", response.data.msg, "success");
            this.$emit('realoadLifeBaseList', true);
          this.disabled = true;
        }
          this.$refs.wizard.navigateToTab(0)
        loader.hide();
      })
      .catch(error => {
        loader.hide();
        Swal.fire("Erreur!", error, "error")
      })
    },
    deleteBase(name, uuid){
      Swal.fire({
        title: "Êtes-vous sûr de supprimer la base  " + name + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("base/lifebases/delete" ,{'uuid':uuid})
            .then((response) => {
              var data = response.data;
              if(data.errors){
                  this.$toast.error(data.msg);
              }else{
                this.clearForm();
                this.$emit('realoadLifeBaseList', true);
                
                this.$toast.success(data.msg);
                this.disabled = true;
                //  this.getLifeBases();
              }
              loader.hide();
              })
              .catch((error) => {
                loader.hide();
                Swal.fire("Erreur!", error, "error")
            })
            .finally(() => {});
        }
      });
    }
  }
    
}
</script>
<style scoped>
  .finish-button{
    background-color:#43A047 !important;
    border-color: #43A047 !important;
  }
  #Sticky {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
  .rounded-pill{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50%;
  }
  .btn-add{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicate{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #8FC412 ;
  }
  .btn-edit{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-delete{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-upload{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-download{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-print{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
</style>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      
      <div class="col-12 mt-2 ">
        <!-- <b-tabs content-class="mt-0 " nav-class="nav-tabs-custom" justified>
          <b-tab title="Informations Générales" active> -->
                <div class="card-body">
                    <ul id="Sticky">
                      <li>
                        <button type="button" :disabled="disabledAdd" @click="addBtn" class="btn btn-add rounded-pill float-right mr-1 mt-1">
                        <i class="fas fa-plus text-white"></i>
                        </button>
                      </li>
                      <li>
                        <button type="button" :disabled="disabledEdit" @click="editBtn" class="btn btn-edit rounded-pill float-right mr-1 mt-1">
                        <i class="fas fa-pencil-alt text-white"></i>
                        </button>
                      </li>
                      <li>
                        <button type="button" :disabled="disabledDelete" @click="deleteBase(info.name,info.uuid)" class="btn btn-delete rounded-pill float-right mr-1 mt-1">
                        <i class="far fa-trash-alt text-white"></i>
                        </button>
                      </li>
                      <li>
                        <button type="button"  class="btn btn-upload rounded-pill d-none float-right mr-1 mt-1">
                        <i class="fas fa-file-upload  text-white"></i>
                        </button>
                      </li>
                      <li>
                        <button type="button"  class="btn btn-download rounded-pill d-none float-right mr-1 mt-1">
                        <i class="fas fa-file-download text-white"></i>
                        </button>
                      </li>
                      <li>
                        <button type="button"  class="btn btn-print rounded-pill d-none float-right mr-1 mt-1">
                        <i class="fas fa-print"></i>
                        </button>
                      </li>
                    </ul>
                  <h4 class="card-title">Détails Base de vie</h4> 
                  <fieldset :disabled="disabled">
                  <form-wizard class="wiz" color="#556ee6" @on-complete="onComplete" ref="wizard" shape="square" error-color="#ff4949">
                    <tab-content title="Informations Générales" icon="mdi mdi-information-variant" :selected="true" >
                      <div class="row">
                            <div class="col-xs-12 col-md-12 col-lg-3">
                              <div class="form-group">
                                <label for="formrow-inputCity">Nom de la Base *</label>
                                <input type="text" :class="{'is-invalid': submitted && $v.info.name.$error}"  class="form-control" required v-model="info.name" />
                              </div>
                            </div>

                            <div class="col-xs-12 col-md-12 col-lg-3">
                              <div class="form-group">
                                <label for="formrow-inputCity">Email *</label>
                                <input type="email" :class="{'is-invalid': submitted && $v.info.email.$error}"   class="form-control" required v-model="info.email" />
                                
                              </div>
                            </div>

                            <div class="col-xs-12 col-md-12 col-lg-3">
                              <div class="form-group">
                                <label for="formrow-password-input">N° Telephone</label>
                                <input type="text" :class="{'is-invalid': submitted && $v.info.phone.$error,}"   class="form-control" v-model="info.phone" />
                              </div>
                            </div>
                            <div class="col-xs-12 col-md-12 col-lg-3">
                              <label for="">Wilaya </label>
                              <multiselect
                                :disabled="disabled"
                                v-model="tableData"
                                :searchable="true"
                                track-by="id"
                                label="designation"
                                :options="state"
                                :allow-empty="false"
                                :select-label="''"
                                :deselect-label="''"
                                :class="{ 'disabledMS': disabled}"
                                @select="assignID"
                              >
                                <template slot="singleCateg" slot-scope="{ wilaya }">{{
                                  wilaya.designation
                                }}</template>
                              </multiselect>
                            
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="formrow-inputCity">Adresse *</label>
                                <textarea  :class="{'is-invalid': submitted && $v.info.address.$error,}"
                                  v-model="info.address"
                                  class="form-control"
                                  cols="30"
                                  rows="1" 
                                  required
                                ></textarea>
                              </div>
                            </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-8">
                            <div class="row" >
                              <h4>Coordonnées GPS de la Base</h4>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <label for="">Latitude (Vertical)</label>
                                <input type="text" :class="{'is-invalid': submitted && $v.info.latitude.$error,}"  class="form-control" v-model="info.latitude" >
                              </div>
                              <div class="col-lg-6">
                                <label for="">Longitude (Horizontal)</label>
                                <input type="text" :class="{'is-invalid': submitted && $v.info.longitude.$error,}"  class="form-control" v-model="info.longitude" >
                              </div>
                            </div>
                          </div>
 
                          <div class="col-lg-4">
                            <div class="row">
                              <h4>Superficie de la Base</h4>
                            </div>
                            <div class="row">
                                <div class="col">
                                  <label for="">Superficie (Km²)</label>
                                  <input type="number" :class="{'is-invalid': submitted && $v.info.area.$error,}"  class="form-control" min="0" v-model="info.area" >
                                </div>
                            </div>
                            
                          </div>
                          
                        </div>

                      <!-- end row -->
                    </tab-content>
                    <tab-content icon="mdi mdi-map-search" title="Coordonnées InfraStructurels">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md">
                              <label for="">Nombre de Regions</label>
                              <input type="number" :class="{'is-invalid': submitted && $v.info.regions.$error,}"  class="form-control" v-model="info.regions" >
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md">
                              <label for="">Nombre de Sites</label>
                              <input type="number" :class="{'is-invalid': submitted && $v.info.sites.$error,}"  class="form-control" v-model="info.sites" >
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md">
                              <label for="">Nombre de Zones</label>
                              <input type="number" :class="{'is-invalid': submitted && $v.info.zones.$error,}"  class="form-control" v-model="info.zones" >
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md">
                              <label for="">Nombre Blocs</label>
                              <input type="number" :class="{'is-invalid': submitted && $v.info.blocs.$error,}"  class="form-control" v-model="info.blocs" >
                            </div>
                          <div class="col-xs-12 col-sm-6 col-md">
                            <label for="">Nombre de Chambres</label>
                            <input type="number" :class="{'is-invalid': submitted && $v.info.rooms.$error,}"  class="form-control" v-model="info.rooms" >
                          </div>
                        </div>
                        <div class="row  mt-2">
                          <div class="col-xs-12 col-sm-6 col-md">
                            <label for="">Nombre de Bureaux</label>
                            <input type="number" :class="{'is-invalid': submitted && $v.info.offices.$error,}"  class="form-control" v-model="info.offices" >
                          </div>
                          <div class="col-xs-12 col-sm-6 col-md">
                            <label for="">Nombre de Cuisines</label>
                            <input type="number" :class="{'is-invalid': submitted && $v.info.kitchens.$error,}"  class="form-control" v-model="info.kitchens" >
                          </div>
                          <div class="col-xs-12 col-sm-6 col-md">
                            <label for="">Nombre de Restaurants</label>
                            <input type="number" :class="{'is-invalid': submitted && $v.info.restaurants.$error,}"  class="form-control" v-model="info.restaurants" >
                          </div>
                          <div class="col-xs-12 col-sm-6 col-md">
                              <label for="">Nombre de Magasins</label>
                              <input type="number" :class="{'is-invalid': submitted && $v.info.stores.$error,}"  class="form-control" v-model="info.stores" >
                            </div>
                          <div class="col-xs-12 col-sm-6 col-md">
                              <label for="">Nombre de Loisirs</label>
                              <input type="number" :class="{'is-invalid': submitted && $v.info.hobbies.$error,}"  class="form-control" v-model="info.hobbies" >
                            </div>
                        </div>
                    </tab-content>
                    <tab-content icon="mdi mdi-checkbox-marked-circle-outline" title="Résumé">
                      <div class="row">
                        <div class="col-lg-6">
                            <h4 class="card-title pl-2 mb-4">Informations Générales</h4>
                            <table class="table table-nowarp mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">Nom de la Base</th>
                                  <td> {{info.name}} </td>
                                </tr>
                                <tr>
                                  <th scope="row">Email</th>
                                  <td>{{info.email}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Telephone</th>
                                  <td>{{info.phone}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Wilaya</th>
                                  <td>{{info.state}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Addresse</th>
                                  <td>{{info.address}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Superficie (Km²)</th>
                                  <td>{{info.area}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Latitude</th>
                                  <td>{{info.latitude}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Longitude</th>
                                  <td>{{info.longitude}}</td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                        <div class="col-lg-6">
                            <h4 class="card-title pl-2 mb-4">Informations InfraStructurels</h4>
                            <table class="table table-nowarp mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">Nombre de Regions</th>
                                  <td> {{info.regions}} </td>
                                </tr>
                                <tr>
                                  <th scope="row">Nombre de Sites</th>
                                  <td> {{info.sites}} </td>
                                </tr>
                                <tr>
                                  <th scope="row">Nombre de Zones</th>
                                  <td>{{info.zones}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Nombre de Blocs</th>
                                  <td>{{info.blocs}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Nombre de Chambres</th>
                                  <td> {{info.rooms}} </td>
                                </tr>
                                <tr>
                                  <th scope="row">Nombre de Bureaux</th>
                                  <td>{{info.offices}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Nombre de Restaurants</th>
                                  <td>{{info.restaurants}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Nombre de Cuisines</th>
                                  <td>{{info.kitchens}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Nombre de Magasins</th>
                                  <td> {{info.stores}} </td>
                                </tr>
                                <tr>
                                  <th scope="row">Nombre de Loisirs</th>
                                  <td>{{info.hobbies}}</td>
                                </tr>
                                
                              </tbody>
                            </table>
                        </div>
                        <!-- end col -->
                      </div>
                      <!-- end row -->
                    </tab-content>
                    <template slot="footer" scope="props">
                      <div class=wizard-footer-left>
                        <wizard-button  v-if="props.activeTabIndex > 0 " @click.native="props.prevTab()" class="wizard-footer-left finish-button   btn-label" :style="props.fillButtonStyle"><i class="fas fa-arrow-left label-icon "></i><span class="ml-4" >Précédent</span></wizard-button>
                      
                      </div>
                      <div class="wizard-footer-right">
                        <button type="button"  @click="cancelBtn" class="btn btn-light btn-label pull-right  mr-2">
                          <i class="fas fa-times-circle text-danger label-icon"></i> Annuler 
                        </button>
                        <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right finish-button  btn-label" :style="props.fillButtonStyle"><i class="fas fa-arrow-right label-icon"></i><span class="ml-3" >Suivant</span></wizard-button>
                        
                        <wizard-button v-else @click.native="onComplete" class="wizard-footer-right btn-primary btn-label " :style="props.fillButtonStyle"><i class="far fa-save label-icon "></i> <span class="ml-4" > Valider</span></wizard-button>
                        
                        
                      </div>
                      
                      </template>
                  </form-wizard>
                  </fieldset>
                </div>
                <!-- end card-body -->
        <!--   </b-tab> -->
          <!-- <b-tab title="Infrastructure">
              <infrastructure/>
          </b-tab> -->
         <!--  <b-tab title="Infos Staff">
              <info-staff :baseID="uuid" />
          </b-tab> -->
          <!-- <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab> -->
       <!--  </b-tabs> -->
        <!-- <form-wizard shape="tab" ref="principal" color="#556ee6">
          <tab-content icon="mdi mdi-information-variant" ref="wizard" title="Informations Générales">
              
          </tab-content>
          <tab-content icon="mdi mdi-office-building" title="Infrastructure">
              
          </tab-content>
          <tab-content icon="mdi mdi-account-network" title="Info Staff">
                      
                      
          </tab-content>
        </form-wizard> -->
        
      </div>
    </div>
    <!-- </Layout> -->
</template>
<style>
  .wiz .wizard-navigation .wizard-progress-with-circle{
    top:18px !important;
  }
  .wiz .wizard-navigation .wizard-nav .wizard-icon-circle{
    width: 27px !important;
    height: 27px  !important;
    font-size: 18px;
  }
  .wiz .wizard-navigation .wizard-nav .checked {
    color: white;
    background-color: #556ee6 !important;
  }
</style>